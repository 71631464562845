<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate'
import { $THETRADEDESK } from '../../../../../../config/dspConfig'
import AlertModel from '@/models/Alertings/AlertModel'

export default {
  name: 'MarkupModuleAlert',
  props: {
    alert: {
      value: {
        type: AlertModel
      }
    }
  },
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      settingsData: [
        { label: 'raise_margin_automatically', value: 'settings.raise_margin_automatically', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'ask_client_validation_for_margin', value: 'settings.ask_client_validation_for_margin', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'control_period', value: 'settings.control_period', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'max_markup_cost_percent', value: 'settings.max_markup_cost_percent', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'field_id', value: 'settings.field_id', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'min_markup_cost_percent', value: 'settings.min_markup_cost_percent', endLabel: '', roundValue: false, isPerc: false, isDate: false }
      ],
      algoStrategyData: [
      ],
      detailedDataRevCpmTrue: [
        { label: 'KPI to check', value: 'data.KPI to check', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'DSP Average KPI', value: 'data.DSP Average KPI', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Surcouche Target KPI', value: 'data.Surcouche Target KPI', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Incremental Profit', value: 'data.Incremental Profit', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Current Min Allowed Margin', value: 'data.Current min allowed margin', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Possible Min Allowed Margin', value: 'data.Possible min allowed margin', endLabel: '', roundValue: false, isPerc: false, isDate: false }
      ],
      detailedDataRevCpmFalse: [
        { label: 'KPI to check', value: 'data.KPI to check', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'DSP Average KPI', value: 'data.DSP Average KPI', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Surcouche Target KPI', value: 'data.Surcouche Target KPI', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Incremental Profit', value: 'data.Incremental Profit', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Current Markup Cost', value: 'data.Current markup cost', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Possible Markup Cost', value: 'data.Possible markup cost', endLabel: '', roundValue: false, isPerc: false, isDate: false }
      ],
      detailedDataSettingsAction: [
        { label: 'KPI to check', value: 'data.KPI to check', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'DSP Average KPI', value: 'data.DSP Average KPI', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Surcouche Target KPI', value: 'data.Surcouche Target KPI', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Incremental Profit', value: 'data.Incremental Profit', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Current Min Allowed Margin', value: 'data.Current min allowed margin', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Possible Min Allowed Margin', value: 'data.Possible min allowed margin', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Current Markup Cost', value: 'data.Current markup cost', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Possible Markup Cost', value: 'data.Possible markup cost', endLabel: '', roundValue: false, isPerc: false, isDate: false }
      ]
    }
  },
  created: function () {
  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    revCpm () {
      return this.alert && this.alert.data && this.alert.data.rev_CPM
    },
    settingsAction () {
      return this.alert && this.alert.settings && this.alert.settings.action === 'ratio'
    },
    isTtd () {
      return this.alert && this.alert.group_key && this.alert.group_key.dsp === $THETRADEDESK
    },
    detailedData () {
      if (this.settingsAction || this.isTtd) {
        return this.detailedDataSettingsAction
      }
      return this.revCpm ? this.detailedDataRevCpmTrue : this.detailedDataRevCpmFalse
    }
  },
  watch: {

  }
}
</script>

<style scoped>

</style>
